.navContainer {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
  position: absolute;
  z-index: 1049;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  .shadow {
    width: 97%;
    margin-top: 1rem;
    background-color: rgba(248, 179, 42, 0.4784313725);
    border-radius: var(--radius);
    color: #fff;
    padding: 21px 113px;
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    // top: -4px;
    top: 0;
    z-index: -1;
  }

  .navbar {
    width: 100%;
    margin-top: 1.5rem;
    background-color: var(--secondaryColor);
    border-radius: var(--radius);
    color: #fff;
    padding: 13px 16px;

    @media screen and (max-width: 768px) {
      margin-top: 0rem;
      border-radius: 0px;
      padding: 0;
    }

    .flex-nav {
      display: flex;
      gap: 0.5rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .left-nav {
        min-width: 224px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-right: 1px solid rgba(240, 236, 236, 0.39);

        @media screen and (max-width: 768px) {
          padding: 15px 20px;
          width: 100%;
          border-right: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.39);
        }

        .logo {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          // width: 30px;
          height: 30px;
          text-align: center;
          a {
            height: 30px;
          }
          img {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: 1280px) {
            .heading {
              display: none;
            }
          }
        }

        .mobile-menu {
          display: none;

          .icon {
            font-size: 30px;
          }

          @media screen and (max-width: 768px) {
            display: block;
          }
        }
      }

      .right-nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;

        @media screen and (max-width: "768px") {
          padding: 10px 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.39);
        }

        .sub-menu {
          display: flex;
          align-items: center;
          gap: 0.2rem;

          .img {
            display: flex;
            align-items: center;
            width: 18px;
            width: 18px;

            img {
              margin-top: 4px;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }

        .bio {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .inputfield {
            position: relative;

            input {
              width: 200px;
              padding: 10px 20px;
              border-radius: 100px;
              transition: width 0.7s;

              &:focus-within {
                transition: 0.7s;
                width: 250px;
              }

              @media screen and (max-width: 640px) {
                display: none;
              }
            }

            .icon {
              font-size: 20px;
              color: black;
              position: absolute;
              top: 8px;
              right: 12px;
              display: flex;
              justify-content: center;
              align-items: center;

              @media screen and (max-width: 640px) {
                color: #fff;
                top: -11px;
                right: 0px;
                font-size: 20px;
              }
            }
          }

          .btn-group {
            .btn {
              --bs-btn-padding-x: 0rem !important;
              --bs-btn-padding-y: 0rem !important;
              --bs-btn-active-bg: none !important;
              --bs-btn-border-color: none;
            }

            .btn-secondary {
              --bs-btn-bg: none !important;
              --bs-btn-hover-bg: none !important;
            }

            .dropdown-item {
              color: #fff !important;
            }

            .dropdown-menu {
              --bs-dropdown-bg: var(--primary);
              --bs-dropdown-color: #fff;
            }

            .dropdown-item {
              item:active {
                color: var(--bs-dropdown-link-active-color);
                text-decoration: none;
                background-color: #fff !important;
              }
            }
          }

          .img {
            height: 40px;
            width: 40px;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .notification {
            .icon {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

.ant-popover {
  z-index: 99999999999999999;
}

.d-title {
  .skill {
    font-size: 0.75rem;
  }
}

.d-bio {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  a {
    display: flex;
    color: #000000;
    gap: 0.4rem;
    border-radius: 5px;
    padding: 5px;

    .icon {
      height: 18px;
      width: 18px;
    }

    &:hover {
      background: rgb(221, 221, 221);
    }
  }

  .border {
    border-top: 1px solid rgb(139, 139, 139);
  }
}

// :where(.css-dev-only-do-not-override-26rdvq).ant-popover .ant-popover-inner{
//     width: 200px !important;
// }
