.sidebar-container {
    display: flex;
    max-height: 100vh;

    .sider {
        padding-top: 120px;
        height: 100vh;
        display: grid;
        grid-template-rows: auto 45px;
        background-color: var(--primaryColor);
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        transition: all .3s;

        @media screen and (max-width:768px) {
            display: none;
        }

        .ant-menu-root {
            height: calc(100vh - 178px);
            background-color: inherit;
            border: none;
            overflow-y: scroll;

            // .ant-menu-item-selected {
            //     background-color: var(--themeColor);
            //     color: black;

            //     &:hover {
            //         color: var(--themeColorGreen);
            //     }
            // }
        }

        .ant-menu-inline .ant-menu-item {
            margin-inline: 8px !important;
            margin-block: 4px !important;
            width: calc(100% - 16px) !important;
        }

        .ant-menu-item {
            color: #fff !important;

            &:hover {
                color: #000000;
            }
        }

        .ant-menu-item-selected {
            color: #fff !important;
            background-color: var(--secondaryColor) !important;
        }

        .ant-menu-item:not(.ant-menu-item-selected):hover {
            color: #fff !important;
            background-color: var(--secondaryColor) !important;
        }

        .ant-layout-sider-trigger {
            padding: .2rem .5rem;
            cursor: pointer;
            transition: all .3s;
        }

        .ant-layout-sider-trigger {
            background-color: var(--secondaryColor);
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                color: #fff !important;
            }
        }
    }

    .right-pages {
        width: 100%;
        overflow-y: scroll;
        padding: 8rem 1.5rem 0rem 1.5rem;

        @media screen and (max-width:768px) {
            padding-top: 9.5rem;
        }
    }

}