* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  // --primaryColor: #014f5b;
  // --secondaryColor: #00cf6f;
  --primaryColor: #df7401;
  --secondaryColor: #0f172b;
  // --primaryColor: #00689d;
  // --secondaryColor: #dd1a22;
  --primaryFontColor: #fff;
  --secondaryFontColor: #000;
  --primaryFont: 14px;
  --radius: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
  @media screen and (max-width: 770px) {
    font-size: 1.5rem;
  }
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
  font-family: "Pacifico", cursive;
  color: var(--primaryColor);
}

h5 {
  font-size: 1.25rem;
}

p {
  font-size: 17px;
}

::-webkit-scrollbar {
  display: none;
}

.cursor {
  cursor: pointer;
}

.flexLine {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

// ********************* Form *********************

.ant-form-item {
  margin-bottom: 15px !important;
}
.ant-form-item-label {
  padding: 0 0 3px !important;
}
.ant-form-item-label > label {
  font-size: 16px;
  font-weight: 500;
  color: #c1c2c5;
}
.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: -2px;
  top: 0px;
}
