.client-table-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .add-job {
    display: flex;
    justify-content: end;
  }
}
.avatar-box {
  padding-left: 20px;
}
