.smart-home-container {
  .heading {
    width: 100%;
    text-align: center;
  }

  .flex-card {
    width: 100%;
    height: calc(100vh - 13rem);
    ;
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;

    .smart-card {
      width: 200px;
      height: 200px;
      padding: 10px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

      .place {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
      }

      .toggle-button {
        padding-top: 10px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
      }
    }
  }
}