.main-container {
  margin: 1.5rem 3rem ;
  position: relative;

  .upper-container {
    border: 2px solid green;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    background-color: rgba(0, 128, 0, 0.8);
    color: white;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;

    svg {
      color: white;
      font-size: 1.5rem;
      cursor: pointer;
    }

    p {
        margin-right: 5px;

        @media screen and (max-width : 550px) {
            font-size: 0.8rem;
        }
    }

    @media screen and (max-width: 550px) {
        padding: 1rem;
    }
  }

  svg {
    color: #4a4949;
  }

  form {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-bottom: 5px;
    justify-content: center;

    h2 {
      margin-right: 10px;

      @media screen and (max-width: 550px) {
        font-size: 1.2rem;
      }

      @media screen and (max-width : 430px) {
        font-size: 1rem;
      }

    }

    .form {
      display: flex;
      align-items: center;
      border-bottom: 2px solid #4a4949;
      padding: 3px;

      @media screen and (max-width: 550px) {
        width: 40%;
      }

      @media screen and (max-width: 430px) {
        padding: 1px;
      }

      input {
        font-size: 1.2rem;
        border: 0px;
        outline: none;
        background: transparent;

        @media screen and (max-width: 550px) {
          width: 100%;
          font-size: 1rem;
        }

        @media screen and (max-width: 430px) {
          font-size: 0.8rem;
        }
      }

      button {
        background: transparent;
        border: none;
        cursor: pointer;
        margin-left: 4px;

        svg {
          color: #4a4949;
          font-size: 1.4rem;

          @media screen and (max-width: 430px) {
            font-size: 1.1rem;
          }
        }
      }
    }

  }

  .error-container {
    margin-top: 50px;
    border: 2px solid red;
    padding: 3rem;
    border-radius: 10px;
    background-color: rgba(255, 0, 0, 0.5);
    color: white;

    h1 {
      margin-bottom: 1rem;

      @media screen and (max-width: 550px) {
            font-size: 1.5rem;
      }

      @media screen and (max-width: 430px) {
        font-size: 1.2rem;
      }
    }
  }

  .main-box {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;

    h1 {
      display: flex;
      align-items: end;

      @media screen and (max-width: 550px) {
            font-size: 1.5rem;
      }

      @media screen and (max-width: 430px) {
        font-size: 1.2rem;
      }
      

      svg {
        margin-right: 0.5rem;
      }
      
      span {
        margin-left: 5px;
      }
    }
  }

  .main-card {
    h2 {
      color: white;
      padding: 0.5rem;
      background-color: #4a4949;

      @media screen and (max-width: 550px) {
        font-size: 1.2rem;
      }

      @media screen and (max-width : 430px) {
        font-size: 1rem;
      }
    }

    & > div {
      display: flex;
      width: 100%;
      border: 2px solid #4a4949;
      padding: 5px;

      .card-items {
        width: 50%;

        &:first-child{
            width: 70%;
        }
        &:last-child{
            width: 30%;
        }

        h5 {
          font-size: 1.2rem;
          font-weight: normal;
          padding: 5px;
          border: 1px solid #4a4949;
          border-bottom: 0px;

          @media screen and (max-width: 550px) {
              font-size: 0.8rem !important;
          }

          svg {
            margin-right: 8px;
            font-size: 1.1rem;

            @media screen and (max-width: 550px) {
              font-size: 0.7rem;
            }
          }
          &:last-child {
            border-bottom: 1px solid #4a4949;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 1.5rem;
  }

  @media screen and (max-width : 590px) {
    margin: 1.5rem 0.5rem;
  }
}
