.loginContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loginForm {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        margin: 0 1.5rem;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
        overflow: hidden;

        .welcomeBox {
            min-height: 180px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 16px;
            gap: 0.5rem;
            background-color: var(--primaryColor);
            color: #fff;

            @media screen and (max-width:500px) {
                min-height: 150px;
            }

            h1 {
                font-size: 25px;

                @media screen and (max-width:500px) {
                    font-size: 22px;
                }
            }

            p {
                font-size: 16px;

                @media screen and (max-width:500px) {
                    font-size: var(--primaryFont);
                }
            }
        }

        .formBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding: 1.5rem;

            .forgotPasswod {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: -15px;

                .forgot {
                    color: var(--primaryColor);
                    font-size: var(--primaryFont);
                }

                .checkbox {
                    color: var(--primaryColor);
                    font-size: var(--primaryFont);
                    display: flex;
                    gap: .3rem;

                    input {
                        height: 19px;
                        width: 19px;
                        accent-color: var(--primaryColor);
                    }
                }

            }

            .loginButton {
                width: 100%;
                padding-top: 2.5rem;
                display: flex;
                gap: 0 1rem;
                justify-content: flex-end;
            }
        }
    }
}