@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;1,300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@400;500;600;700&display=swap");

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import "node_modules/react-modal-video/scss/modal-video.scss";
body {
  font-family: "Open Sans", sans-serif;
  background-color: rgb(240, 239, 239);
}
body {
  font-family: "Montserrat", sans-serif;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  @media screen and (max-width: 1200px) {
    max-width: 960px;
  }
  @media screen and (max-width: 990px) {
    max-width: 720px;
  }
  @media screen and (max-width: 768px) {
    max-width: 540px;
  }
}
.spacing {
  margin-top: 6rem;
  @media screen and (max-width: 1200px) {
    margin-top: 5rem;
  }
  @media screen and (max-width: 990px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 768px) {
    margin-top: 3rem;
  }
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.avatar-uploader {
  .selected-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
  display: flex !important;
  justify-content: center !important;
}
