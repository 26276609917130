.client-form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .issue-form-container {
    padding: 20px 15px;
    border-radius: 8px;
    border: 2px solid rgba(199, 20, 20, 0.12);

    .primary-btn {
      width: 100px;
    }
  }
}
